.inner-welcome-page-container {
    background-color: #2e2e2e;
    width: 80%;
    height: 50vh;
}

/* Pulzáló animáció */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.welcome-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 85vh;
    background-color: #222 !important;
    color: white;
    font-family: Arial, sans-serif;
}

.h1_welcome_page {
    all: unset;
    font-family: 'Russo', sans-serif;
    background-color: white;
    border-radius: 20px;
    padding: 20px 140px;
    margin: 4%;
    margin-top: 20px !important;
    overflow: hidden;
    font-size: 40px !important;
    color: #b30000 !important;
}

/* Alap beállítások */
.wp_img {
  width: 400px;
  animation: pulse 1.5s infinite;
}

/* Mobilnézet */
@media (max-width: 768px) {
    .inner-welcome-page-container {
        width: 100%;
        height: 40vh;
        padding: 20px;
    }

    .h1_welcome_page {
        padding: 5px 5px;
        font-size: 12px !important;
    }

    .wp_img {
        width: 250px;
    }
}
