@media (max-width: 768px) {
  /* Styles for mobile screens */
  .recharts-responsive-container {
    height: 90%;
    max-height: 200px;  /* Ensure it doesn't collapse on mobile */
  }

  .recharts-legend-wrapper {
    font-size: 10px !important;  /* Smaller font size for legend */
    margin-top: 10px !important;
    bottom: -10px !important;
    left: 20% !important;
  }

  .recharts-tooltip-wrapper {
    font-size: 10px !important;  /* Smaller font size for tooltip */
    
  }

  .recharts-xAxis, .recharts-yAxis {
    font-size: 10px !important;  /* Smaller axis font for mobile */
  }

  .recharts-line {
    stroke-width: 1.5px;  /* Smaller line width for mobile */
  }

  .recharts-cartesian-grid-horizontal,
  .recharts-cartesian-grid-vertical {
    stroke-width: 1px;  /* Thinner grid lines on mobile */
  }

    .chart-container{
        margin-left: -12%;
        margin-right:0px;
    }


}

/* Alapértelmezett stílusok, amelyeket alkalmazunk minden képernyőmérethez */
/* Alapértelmezett stílusok, amelyeket alkalmazunk minden képernyőmérethez */
  .recharts-responsive-container {
  width: 100% !important;  /* Alapértelmezett szélesség 100% */
  height: 800px !important;  /* Alapértelmezett magasság */
  min-height: 300px;  /* Minimum magasság, ha valami miatt nincs elegendő tartalom */
  box-sizing: border-box;  /* Biztosítja, hogy a padding ne változtassa meg a magasságot */
    margin-right: 3% !important;
    padding-right: 4% !important;
}



/* Input mezők stílusa */
input[type='number'] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 20px;
  text-align: center; /* középre igazított számok */
}

/* Textarea */
textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 20px;
  resize: none;
}

/* Gombok stílusa */
button {
  background-color: red;
  color: white;
  font-size: 18px;
  border: 2px solid black;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: darkred;
}

button:focus {
  outline: none;
}
