.sep_footer_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 115vh;
    background-color: #222;
    color: white;
    font-family: Arial, sans-serif;
}

.sep_container {
    width: 90%;
    padding: 20px;
    background: #333;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: -15vh;
}

.sep-header {
    margin-bottom: 30px;
}

.sep-title {
   font-family: 'Russo', sans-serif;
   
    background-color: white;
    border-radius: 20px;
    padding: 20px 140px;
    margin: 4%;
    margin-top: 20px !important;
    overflow: hidden;
    font-size: 24px !important;
    color: #b30000 !important;
}

.sep_container input,
.sep_container textarea,
.sep_container button {
    width: 70%;
    padding: 12px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
}

.sep_container input,
.sep_container textarea {
    background: #fff;
    color: #000;
}

.sep_container button {
    background: #b30000;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.sep_container button:hover {
    background: #ff0000;
}

.sep_footer {
    width: 100%;
    height: 15vh;
}

.sep_footer_top {
    padding: 0;
}

/* Datepicker styles */
.sep_datepicker-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.sep_datepicker {
    margin-top: -10px !important;
    width: 420px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    transition: 0.3s;
}

.sep_datepicker:focus {
    outline: none;
    border-color: #960000;
    box-shadow: 0 0 5px #960000;
}

/* React-datepicker styles remain unchanged */
.react-datepicker {
    height: 400px;
    font-family: 'Russo', sans-serif;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
    background-color:  #600000;
    color: white;
    border-bottom: 1px solid black;
    padding: 10px;
    height: 120px;
}

.react-datepicker__header__dropdown {
    margin-top: 10px;
    color: white;
}

.react-datepicker__year-dropdown-container {
    color: black;
    background-color: #600000;
}

.react-datepicker__year-read-view--selected-year {
    color: white;
}

.react-datepicker__header h2 {
    margin-top: 15px;
    color: white !important;
}

.react-datepicker__day-name {
    padding: 8px;
    color: white;
}

.react-datepicker__day {
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
}

.react-datepicker__day:hover {
    background-color: #960000;
    color: white;
}

.react-datepicker__day--selected {
    background-color: #800000 !important;
    color: white !important;
}

.react-datepicker__day--today {
    font-weight: bold;
    color: #800000;
}

.react-datepicker__day--disabled {
    color: #ccc;
    cursor: not-allowed;
}

.react-datepicker__navigation {
    top: 10px;
}

/* Time picker */
.sep_timePicker {
    justify-self: center;
    width: 15% !important;
    color: black;
}

.sep_timePicker::-webkit-calendar-picker-indicator {
    cursor: pointer;
}

.sep_timePicker:focus,
.sep_timePicker:active {
    background-color: #800000;
    border-color: #960000;
    box-shadow: 0 0 5px #ff0000;
}

/* Button */
.sep-button-send {
    background-color: #960000;
    color: #fff;
    border: 2px solid rgb(0, 0, 0);
    font-size: large !important;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
    font-family: 'Russo', sans-serif !important;
    height: 10% !important;
    width: 40% !important;
}

.sep-button-send:hover {
    background-color: #FFF;
    color: #000;
}

.react-datepicker button {
    width: 40px;
    margin: 10px;
}

.react-datepicker__close-icon {
    display: none !important;
}

/* Switch and loading */
.sep_switch-container {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sep_loading-container {
    text-align: center;
}

.sep_loading-image {
    width: 100px;  /* Adjust size as needed */
    height: 100px;
    animation: sep-rotate 2s linear infinite;
}

@keyframes sep-rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.sep_info_paragraph {
    font-family: 'Russo', sans-serif;
    margin-top: 10px;
    font-size: large;
}

/* Responsive styles */
@media only screen and (max-width: 767px) {
    .sep_footer_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 155vh;
        background-color: #222;
        color: white;
    }

    .sep_right-column {
        height: 100vh;
        all: unset;
        background-color: none;
        margin-top: 5vh;
    }

    .sep_container {
        height: 120vh;
        width: 90%;
        padding: 20px;
        background: #333;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .sep-header {
        margin-bottom: 10px;
    }

    .sep-title {
        background-color: white;
        border-radius: 12px;
        padding: 12px 10px;
        margin: 4%;
        font-size: 18px !important;
        color: #b30000 !important;
    }

    .sep_container input,
    .sep_container textarea,
    .sep_container button {
        width: 70%;
        padding: 12px;
        margin-top: 20px;
        border: none;
        border-radius: 5px;
    }

    .sep_container input,
    .sep_container textarea {
        background: #fff;
        color: #000;
    }

    .sep_container button {
        background: #b30000;
        color: white;
        font-weight: bold;
        cursor: pointer;
    }

    .sep_container button:hover {
        background: #ff0000;
    }

    .sep_footer {
        width: 90%;
        height: 30vh;
    }

    .sep_footer_top {
        padding: 0;
    }

    input[type="text"] {
        font-size: 12px;
    }

    .sep_datepicker-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .sep_datepicker {
        margin-top: -10px !important;
        width: 420px;
        padding: 10px;
        font-size: 12px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: white;
        cursor: pointer;
        transition: 0.3s;
    }

    .sep_datepicker:focus {
        outline: none;
        border-color: #960000;
        box-shadow: 0 0 5px #960000;
    }

    .react-datepicker {
        height: 400px;
        font-family: 'Russo', sans-serif;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

    .react-datepicker__header {
        background-color:  #600000;
        color: white;
        border-bottom: 1px solid black;
        padding: 10px;
        height: 120px;
    }

    .react-datepicker__header__dropdown {
        margin-top: 10px;
        color: white;
    }

    .react-datepicker__year-dropdown-container {
        color: black;
        background-color: #600000;
    }

    .react-datepicker__year-read-view--selected-year {
        color: white;
    }

    .react-datepicker__header h2 {
        margin-top: 15px;
        color: white !important;
    }

    .react-datepicker__day-name {
        padding: 8px;
        color: white;
    }

    .react-datepicker__day {
        padding: 8px;
        border-radius: 50%;
        cursor: pointer;
    }

    .react-datepicker__day:hover {
        background-color: #960000;
        color: white;
    }

    .react-datepicker__day--selected {
        background-color: #800000 !important;
        color: white !important;
    }

    .react-datepicker__day--today {
        font-weight: bold;
        color: #800000;
    }

    .react-datepicker__day--disabled {
        color: #ccc;
        cursor: not-allowed;
    }

    .react-datepicker__navigation {
        top: 10px;
    }

    .sep_timePicker {
        justify-self: center;
        width: 35% !important;
        color: black;
    }

    .sep_timePicker::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }

    .sep_timePicker:focus,
    .sep_timePicker:active {
        background-color: #800000;
        border-color: #960000;
        box-shadow: 0 0 5px #ff0000;
    }

    .sep-button-send {
        background-color: #960000;
        color: #fff;
        border: 2px solid rgb(0, 0, 0);
        font-size: large !important;
        border-radius: 10px;
        cursor: pointer;
        margin-top: 10px;
        font-family: 'Russo', sans-serif !important;
        height: 10% !important;
        width: 40% !important;
    }

    .sep-button-send:hover {
        background-color: #FFF;
        color: #000;
    }

    .react-datepicker button {
        width: 40px;
        margin: 10px;
    }

    .react-datepicker__close-icon {
        display: none !important;
    }

    .sep_switch-container {
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .sep_loading-container {
        text-align: center;
    }

    .sep_loading-image {
        width: 100px;
        height: 100px;
        animation: sep-rotate 2s linear infinite;
    }
}
