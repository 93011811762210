.blog-container {
    display: grid;
    grid-template-columns: 20% 60% 20%;  /* 3 columns: 20% for menu, 60% for content, 20% for empty space */
    background-color: #2e2e2e;
    color: white;
    margin: 0;
    padding: 0;
    font-family: 'Russo', sans-serif;
  height: auto;/* Ensure the container spans the full viewport height */
}

.blog-content h2 {
    font-size: medium;
}

.menu ul li .a_active {
    background-color: #f1c40f;
    color: #2e2e2e;
}

.menu {
    background-color: transparent;
    padding: 20px;
    display: flex;
    flex-direction: column;

}

.menu ul {
    padding: 0;
    font-size: x-small;
}

.menu ul li {
    list-style-type: none;
    margin: 10px 0;
}

.menu ul li a {
    text-decoration: none;
    color: white;
    padding: 10px;
    display: block;
    background-color: rgb(106, 0, 0);
    border: 1px solid black;
    text-align: center;
    border-radius: 4px;
}

.menu ul li a:hover {
    background-color: #f1c40f;
    color: #2e2e2e;
}

.button_a {
    text-decoration: none;
    color: white;
    padding: 10px;
    display: block;
    background-color: rgb(106, 0, 0);
    border: 1px solid black;
    text-align: center;
    border-radius: 4px;
}

.button_a:hover {
    background-color: #f1c40f;
    color: #2e2e2e;
}

.blog-content {
    background-color: #1c1c1c;

    /*overflow-y: auto; /* Add scroll if content is too long */
}

.empty-space {
    /* Empty space for future use */
}

.bela {
    margin-top: -4.5%;
    font-size: larger;
    margin-bottom: 20px;
    color: #f1c40f;
    display: block;
  
}

li strong {
    font-size: medium;
    color: #c11300
}

p, ol {
    font-size: small;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: justify;
}

ol li {
    margin-bottom: 10px;
}

strong {
    color: #9e1000;
}

.author_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1%;
    margin-top: 20px;
}

.author-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid white;
    margin-top: -25px;
}

.author-text {
    margin-top: -20px;
    margin-left: 10px;
}

.welding-image_why {
    width: 40%;
    height: auto;
    margin-top: 20px;
    padding-left: 30%;
}


@media screen and (max-width: 767px) {

    .blog-content {
        text-align: justify; /* Sorkizárt szöveg */
        padding: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

  .welding-image_why-mobile {
    width: 80%;
    height: auto;
    margin-top: 20px;
    padding-left: -30% !important;
    padding-bottom: 4%;
}  
}